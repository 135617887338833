<template>
	<!-- 帮助 -->
	<div class="auto-adjust-edit">
		<!-- 返回 -->
		<!-- <div style="position: fixed;margin: 0 30px 0 0;cursor: pointer; right: 0; top: 65px;z-index: 99;" @click="routerGo">
			<i class="iconfont icon-fanhui-icon" style="color: #8f8f8f"></i>
			<span style="color: #8f8f8f">返回</span>
		</div> -->
		<div class="operation">
			<div class="operation-btn">
				<!-- 快捷按钮 -->
				<el-tabs v-model="dateName" :tab-position="tabPosition" style="height: auto">
					<el-tab-pane :name="item.name" v-for="(item, index) in dateList" :key="'date' + index" :label="item.name"></el-tab-pane>
				</el-tabs>
			</div>
		</div>

		<!-- 滚动区域 -->

		<!-- 体能系统（V6.0.1）-2023年2月15日更新日志



 -->

		<div class="scroll-content" v-show="dateName == '2023/02/15'">
			<div class="scroll-content-title" title="2023/02/15" name="oc_title">2023/02/15 更新日志（V6.0.1）</div>
			<div class="scroll-item">
				<!-- <p style="text-align: center; font-size: 20px; color: #000">体能系统（V6.0.1）-2023年2月15日更新日志</p> -->
				<ul class="list-padding left-2" style="margin-top: 10px">
					<li><p>1.状态监控-主观状态-今日状态自评下“肌肉酸痛度”改为“肌肉恢复度”，方便用户录入（运动员小程序端同步优化）</p></li>
					<li><p>2.修复了人员管理模块下的已知BUG</p></li>
					<li><p>3.修复了测试管理-身体功能测试模块下编辑页面的已知BUG</p></li>
					<li><p>4.优化了页面尺寸放大、缩小时页面自适应的问题</p></li>
					<li><p>5.新增更新日志的功能</p></li>
				</ul>
				<p><br /></p>
			</div>
		</div>

		<div class="scroll-content" v-show="dateName == '2023/11/17'">
			<div class="scroll-content-title" title="2023/11/17" name="oc_title">2023/11/17 更新日志（V6.2.0）</div>
			<div class="scroll-item">
				<!-- <p style="text-align: center; font-size: 20px; color: #000">体能系统（V6.0.1）-2023年2月15日更新日志</p> -->
				<ul class="list-padding left-2" style="margin-top: 10px">
					<li><p>1. 测试管理模块</p></li>
					<li><p class="two_li">1.1 YBT-编辑页面</p></li>
					<li><p class="three_li">1.1.1 新增“下肢长度（cm）”、“上肢长度（cm）”输入框，并重新规范YBT下肢测试及YBT上肢测试中其他测试指标的顺序，符合测试及录入逻辑；</p></li>
					<li><p class="three_li">1.1.2 新增运动员个人下肢及上肢YBT测试结果统计图，更直观的反映各指标的测试数据结果。</p></li>
					<li><p class="two_li">1.2 YBT-查看页面</p></li>
					<li>
						<p class="three_li">1.2.1 左右差异值及总分计算：系统可根据用户填写的YBT下肢及上肢测试指标的数据，自动计算出各运动员的左右差异值及总分，便于教练评估运动员是否存在受伤风险；</p>
					</li>
					<li><p class="three_li">1.2.2 颜色标注：对左右差异值及总分根据特定算法进行颜色标注，更直观的识别出指标数据是否通过；</p></li>
					<li><p class="three_li">1.2.3 新增各运动员YBT下肢/上肢测试总分统计图，直观反映运动员的左侧/右侧总分是否通过</p></li>
					<li><p class="three_li">1.2.4 新增各运动员下肢/上肢YBT对称性分析统计图，直观反映各方向差异值是否通过。</p></li>
					<li>
						<img src="./../../static/nx_v1_1.png" alt="" />
					</li>
					<li><p>2. 新增统计分析模块，分为个体分析、报告管理</p></li>
					<li><p class="two_li">2.1 个体分析：根据用户选择的筛选条件，展示数据详情及指标变化趋势</p></li>
					<li><p class="three_li">2.1.1 数据详情：列表展示某时间范围内各项指标的数据，自动计算平均值及标准差，并进行最大值及最小值标注；支持手动调整指标的最大值及最小值；</p></li>
					<li><p class="three_li">2.1.2 指标变化趋势：以折线图的形式反映各项指标的变化趋势，支持日期阶段的设置；</p></li>
					<li><p class="three_li">2.1.3 保存为个体分析报告：将基本信息、数据详情、指标变化趋势的内容以报告的形式进行保存。</p></li>
					<li><p class="two_li">2.2 报告管理：对所有个体分析报告进行管理，可进行查看、删除操作</p></li>
					<li>
						<img src="./../../static/nx_v1_2.png" alt="" />
					</li>
					<li><p>3. 状态监控模块</p></li>
					<li><p class="two_li">3.1 主观状态</p></li>
					<li><p class="three_li">3.1.1 数据范围：展示所选日期近28天的数据，便于观察数据波动；</p></li>
					<li><p class="three_li">3.1.2 新增评价区间：可直观反映出各个数据处于哪种评价；默认评价区间系数为0</p></li>
					<li><p class="three_li">3.1.3 新增状态自评总分；</p></li>
					<li><p class="three_li">3.1.4 新增晨起疼痛自评分数折线图；</p></li>
					<li><p class="three_li">3.1.5 新增每日状态自评及晨起疼痛自评统计表，自动计算总分、平均值、标准差，并对数值进行颜色标注，直观反映对数值的评价。</p></li>
					<li>
						<img src="./../../static/nx_v1_3.png" alt="" />
					</li>
					<li><p>4. 人员管理模块</p></li>
					<li><p class="two_li">4.1增加“是否为重点运动员”字段，可根据实际情况设置重点运动员</p></li>
					<li><p class="two_li">4.2新增人员卡片排序，根据是否为重点运动员字段及人员卡片的点击次数进行排序，方便教练找到近期重点关注的运动员</p></li>
				</ul>
				<p><br /></p>
			</div>
		</div>

		<!-- <div class="scroll-content" v-show="dateName == '2023/04/11'">
			<div class="scroll-content-title" title="2023/04/11" name="oc_title">2023/04/11 更新日志（V6.1.1）</div>
			<div class="scroll-item">
				<ul class="list-padding left-2" style="margin-top: 10px">
					<li><p>1.新增人员卡片排序，根据是否为重点运动员字段及人员卡片的点击次数进行排序，方便教练找到近期重点关注的运动员；</p></li>
					<li><p>2.修复账号权限模块下的已知BUG。</p></li>
				</ul>
				<p><br /></p>
			</div>
		</div> -->

		<!-- <div class="scroll-content" v-show="dateName == '2023/10/26'">
			<div class="scroll-content-title" title="2023/10/26" name="oc_title">2023/10/26 更新日志（V6.2.0）</div>
			<div class="scroll-item">
				<p style="text-align: left; font-size: 20px; color: #000;padding-left: 0;">⭐ 新增功能</p>
				<ul class="list-padding left-2" style="margin-top: 10px">
					<li><p>1、新增设备管理模块，用于管理机能设备与体能设备。</p></li>
					<li>
						<img src="./../../static/v2_1.png" alt="" />
					</li>
					<li><p>2、新增数据管理模块，方便用户查看每款设备下运动员的训练/测试数据，为后期数据分析提供依据。</p></li>
					<li>
						<img src="./../../static/v2_2.png" alt="" />
					</li>
					<li><p>3、新增数据联合分析功能，用于教练多维度分析训练负荷与各指标的关系，并形成可视化统计图。</p></li>
					<li>
						<img src="./../../static/v2_3.png" alt="" />
					</li>
					<li><p>4、新增小程序端的血乳酸值可回传至PC端，便于教练员或医务科研人员查看。</p></li>
					<li>
						<img src="./../../static/v2_4.png" alt="" />
					</li>
				</ul>

				<p style="text-align: left; font-size: 20px; color: #000;padding-left: 0;margin-top: 100px;">⭐ 优化功能</p>
				<ul class="list-padding left-2" style="margin-top: 10px">
					<li><p>1、优化状态监控-身体成分模块，实现在体成分仪（ACCUNIQ X-SCAN PLUS II）中测试的数据回传至PC端。</p></li>
					<li><p>2、优化状态监控-主观状态模块下晨起疼痛自评分数的折线图样式。</p></li>
					<li>
						<img src="./../../static/v2_5.png" alt="" />
					</li>
					<li><p>3、优化统计分析模块页面展示方式。</p></li>
					<li>
						<img src="./../../static/v2_6.png" alt="" />
					</li>
					<li><p>4、优化时间段筛选器的UI风格。</p></li>
					<li>
						<img src="./../../static/v2_7.png" alt="" />
					</li>
				</ul>
				<p><br /></p>
			</div>
		</div> -->
	</div>
</template>
<script>
export default {
	data() {
		return {
			dateName: '2023/11/17',
			dateList: [],
			tabPosition: 'right',
		}
	},
	mounted() {
		let datein = document.querySelectorAll('.scroll-content-title')
		console.log(datein)
		Array.from(datein).forEach(item => {
			this.dateList.unshift({
				name: item.title,
				top: item.offsetTop,
			})
		})
	},
}
</script>

<style lang="scss" scoped>
.two_li {
	padding-left: 20px !important;
}
.three_li {
	padding-left: 40px !important;
}
.operation {
	overflow: hidden;
	width: 220px;
	padding-top: 40px;
	// width: 380px;
	border-right: 0.052083rem solid #d8d6d6;
}
.auto-adjust-edit {
	flex-basis: 100%;
	display: flex;
	overflow: hidden;
	height: 100%;
	margin-left: -25px;
	margin-top: -30px;
	background: #fff;
	// 侧边栏
	.operation-btn {
		position: relative;
		// width: 200px;
		height: 550px;
		overflow-y: auto;
		// margin-top: 10px;
		background: white;
		border-right: 1px solid #d7d7d7;

		.op_tit {
			position: sticky;
			top: 10px;
			z-index: 99; //权重自己设置哦！
			line-height: 55px;
			font-size: 18px;
			background: #fff;
			// margin-left: 30px;
			padding-left: 30px;
			font-weight: 550;
			margin-top: 3px;
			border-bottom: 1px solid #d7d7d7;
			margin-bottom: 10px;
		}
		.btn-item {
			height: 50px;
			line-height: 50px;
			padding-left: 50px;
			cursor: pointer;
		}
	}
	// 表单内容
	.scroll-content {
		height: 100%;
		// width: 90%;
		width: 100%;
		overflow: auto;
		padding: 0 20px;
		.scroll-content-title {
			font-size: 24px;
			padding: 20px 15px 0 10px;
		}
		.scroll-item {
			padding: 20px 15px 0 10px;
			margin-bottom: 6px;
			// border-left: 1px solid rgb(216, 214, 214);
			// min-height: 700px;
			font-size: 16px;
			// 标题
			.part-title {
				height: 40px;
				font-size: 16px;
				font-weight: normal;
				line-height: 40px;
				padding-left: 5px;
			}
			.part-cont,
			p {
				font-weight: 400;
				color: #666666;
				line-height: 32px;
				padding-left: 10px;
				font-size: 16px;
				text-indent: 2em;
			}
			// 表单
			/deep/.el-form {
				border: 1px solid rgb(190, 189, 189);
				width: 98%;
				margin: 10px auto 30px;
				border-radius: 6px;
				/deep/.el-form-item {
					margin-bottom: 12px;
					margin-top: 10px;
				}
			}
			.top1-img {
				width: 100%;
				height: auto;
			}
			li img {
				width: 90%;
				margin: 20px 5%;
			}
		}
	}
}
.sp-col {
	font-weight: bold;
	color: rgb(192, 0, 0);
}
.fomo {
	// font-weight: bold;
	font-size: 16px;
	padding-left: 10px;
}
.one {
	font-size: 18px !important;
	// font-weight: bold !important;
	padding-left: 10px;
}
/deep/ .el-tabs--right .el-tabs__header.is-right {
	float: left;
}
/deep/ .el-tabs__active-bar {
	background: #fff;
	display: none;
}
/deep/ .el-tabs__nav-wrap::after {
	background: #fff;
}
/deep/ .el-tabs__item {
	&:last-child {
		padding: 0 1.041667rem !important;
	}
}
// /deep/ .el-tabs__item{
//   color: pink;
// }
</style>
